
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Detail extends Vue {
  private radio = 'map'

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.radio = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
  }

  handleChange () {
    const path = `/insectMonitor/detail${this.projectId}/${this.radio}`
    this.$router.replace({ path: path })
  }
}
